/* eslint no-unused-expressions: 0 */
import GoogleAnalytics from 'react-ga'
import ReactDOM from 'react-dom'
import { siteMetadata } from './gatsby-config'
export { wrapRootElement } from './apollo/wrap-root'
/**
 * Initialize Google Analytics
 */
export const onClientEntry = () => {
  if (process.env.NODE_ENV === 'production') {
    GoogleAnalytics.initialize(siteMetadata.googleAnalyticsId)
  }
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (!(`IntersectionObserver` in window)) {
    import(`intersection-observer`)
  }
}

export const replaceHydrateFunction = () => {
  return (element, container, callback) => {
    ReactDOM.render(element, container, callback)
  }
}
