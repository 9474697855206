require('dotenv').config({
  path: `.env`,
})

const config = require('./config')

const contentfulConfig = {
  spaceId: process.env.GATSBY_CONTENTFUL_SPACE_ID,
  accessToken: process.env.GATSBY_CONTENTFUL_DELIVERY_API,
  environment: process.env.GATSBY_CONTENTFUL_ENVIRONMENT,
  host: process.env.GATSBY_CONTENTFUL_HOST,
}

module.exports = {
  siteMetadata: {
    title: config.title,
  },
  pathPrefix: '/nightcap',
  plugins: [
    {
      resolve: `gatsby-plugin-google-analytics`,
      options: {
        // The property ID; the tracking code won't be generated without it
        trackingId:
          process.env.GATSBY_GOOGLE_ANALYTICS_TRACKING_ID || 'UA-168560898-1',
        head: true,
        // anonymize: true,
        // respectDNT: true,
        exclude: ['/preview/**'],
        pageTransitionDelay: 0,
      },
    },
    'gatsby-plugin-react-helmet',
    `gatsby-plugin-image`,
    `gatsby-plugin-sharp`,
    `gatsby-transformer-sharp`,
    {
      resolve: `gatsby-plugin-manifest`,
      options: {
        name: config.title,
        short_name: config.shortTitle,
        start_url: `/`,
        background_color: `#0072ce`,
        theme_color: `#ffffff`,
        display: `standalone`,
        cache_busting_mode: 'none',
        icon: `src/assets/android-chrome-256x256.png`,
        icon_options: {
          purpose: `maskable`,
        },
      },
    },
    {
      resolve: `gatsby-plugin-styled-components`,
      options: {
        displayName: true,
        fileName: false,
      },
    },
    {
      resolve: `gatsby-source-contentful-locales`,
      options: {
        spaceId: contentfulConfig.spaceId,
        accessToken: contentfulConfig.accessToken,
      },
    },
    {
      resolve: 'gatsby-source-contentful',
      options: {
        ...contentfulConfig,
      },
    },
    {
      resolve: `gatsby-plugin-s3`,
      options: {
        bucketName:
          'nightcap-irur2cw1dr5z-master',
        acl: null,
      },
    },
    {
      resolve: 'gatsby-source-filesystem',
      options: {
        name: 'assets',
        path: `${__dirname}/src/assets`,
      },
    },
    {
      resolve: 'gatsby-plugin-react-svg',
      options: {
        rule: {
          include: /src\/assets\/svg/,
        },
      },
    },
  ],
}
